module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locales","languages":["ja","en"],"defaultLanguage":"ja","siteUrl":"https://yamamotoiroha.com","i18nextOptions":{"debug":false,"interpolation":{"escapeValue":false},"nsSeparator":false,"defaultNS":"common"},"pages":[{"matchPath":"/:lang?/:page/"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-N9SKVEKY9T"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://yamamotoiroha.com","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"山本いろは オフィシャルサイト","short_name":"山本いろは オフィシャルサイト","start_url":"https://yamamotoiroha.com","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/icon-512x512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1ab2ea70d487cb579983ade466d434bb"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
